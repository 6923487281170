import * as React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import css from './404.module.scss';
import { Button, Layouts } from '../components/components';
import image from '../assets/images/404.svg';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { IndexLayout, Page, Container } = Layouts;
  return (
    <IndexLayout>
      <Page>
        <Container className={css.container_404}>
          <h1 className={css.title}>{t('shell:404.header')}</h1>
          <p className={css.subtitle}>
            {t('shell:404.p')}
          </p>
          <Link to="/">
            <Button className={css.button} border title={t('shell:404.link')} fontWeight={600} width="max-content" uppercase/>
          </Link>
          <img className={css.img404} src={image} alt=""/>
        </Container>
      </Page>
    </IndexLayout>
  );
};

export default NotFoundPage;
